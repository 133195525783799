.headerBar {
    background: white;
    text-align: right;
    display: flex;
    justify-content: space-between;

    img {
        height: 34px;
        margin-right: 6px;
    }
}

.cushmanImage {
    width: 300px;
}
