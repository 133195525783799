.main {
    width: 100%;
    max-height: 100vh;
    background: #f3f3ff;
    box-sizing: border-box;
    overflow: auto;
}

amplify-authenticator {
    margin: 0 !important;
}

.MuiButton-startIcon {
    margin: 0px;
}

.bottomDetailButtons {
    margin-top: 10px;
    button {
        margin: 0 10px;
    }
}

.MuiCard-root {
    border-radius: 20px;
}

.MuiFormControl-fullWidth {
    width: calc(100% - 15px) !important;
}
