.filterAddition {
    display: flex;
    align-items: center;
    .valueInputs {
        display: flex;
        flex-direction: column;
    }
}

.enhancedTableFilter {
    width: 100%;
}
