.equipment-condition-Excellent {
    background: rgba(var(--color-excellent-raw), 0.3);
}

.equipment-condition-Good {
    background: rgba(var(--color-good-raw), 0.3);
}

.equipment-condition-Fair {
    background: rgba(var(--color-fair-raw), 0.3);
}

.equipment-condition-Poor {
    background: rgba(var(--color-poor-raw), 0.3);
}

.equipment-condition-EndOfLife {
    background: rgba(var(--color-endOfLife-raw), 0.3);
}

.equipment-condition-nodata {
    background: rgba(var(--color-nodata-raw), 0.3);
}
