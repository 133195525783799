.mepOption {
    max-width: 40vw;
    white-space: pre-wrap;
    right: 3vw;
}

.mepCostingCreate {
    button {
        margin-right: 5px;
    }

    .MuiFormControl-root {
        margin: 10px;
    }
}
