.barChartRow {
    display: flex;
    height: 1.5rem;
    align-items: center;
    padding: 5px 0;
    font-family: ttc-medium;

    .barChartBarContainer {
        background-color: #f2f4ff;
        border-radius: 0.5rem;
        -webkit-border-radius: 0.5rem;
        -moz-border-radius: 0.5rem;
        -ms-border-radius: 0.5rem;
        -o-border-radius: 0.5rem;
        display: flex;
        align-items: center;
        overflow: hidden;
        flex-grow: 1;

        .barCount {
            padding-left: 5px;
        }
        .barFill {
            opacity: 0.8;
            height: 1.5rem;
            border-radius: 0.5rem;
            -webkit-border-radius: 0.5rem;
            -moz-border-radius: 0.5rem;
            -ms-border-radius: 0.5rem;
            -o-border-radius: 0.5rem;
        }
    }

    .barChatRowLabel {
        min-width: 8rem;
        font-size: 1.5rem;
    }
}
