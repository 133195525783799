.inspectionCreateForm {
    margin-bottom: 10px;
    padding: 15px;
}

.inspectionCreateFormDiv {
    display: flex;
}

@media only screen and (max-width: 600px) {
    .inspectionCreateFormDiv {
        display: flex;
        flex-flow: wrap;
        .radioGroup {
            display: flex;
            flex-flow: wrap;
        }
    }
}

.equipmentDetailSummary div,
.buildingDetailSummary div {
    margin-right: 10px;
}
