@use 'sass:color';

:root {
    --color-excellent: rgb(0, 255, 0);
    --color-good: rgb(0, 0, 255);
    --color-fair: rgb(255, 255, 0);
    --color-poor: rgb(255, 0, 0);
    --color-endOfLife: rgb(111, 0, 255);
    --color-nodata: rgb(0, 0, 0);

    --color-excellent-raw: 0, 255, 0;
    --color-good-raw: 0, 0, 255;
    --color-fair-raw: 255, 255, 0;
    --color-poor-raw: 255, 0, 0;
    --color-endOfLife-raw: 111, 0, 255;
    --color-nodata-raw: 120, 120, 120;
}
