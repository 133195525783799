@import './components/css-variables.scss';

@font-face {
    font-family: ttc-dbold;
    src: url(font/TTCommons-DemiBold.ttf) format('truetype');
}
@font-face {
    font-family: ttc-bold;
    src: url(font/TTCommons-Bold.ttf) format('truetype');
}
@font-face {
    font-family: ttc-medium;
    src: url(font/TTCommons-Medium.ttf) format('truetype');
}
@font-face {
    font-family: ttc-regular;
    src: url(font/TTCommons-Regular.ttf) format('truetype');
}

body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {
    display: flex;
    justify-content: center;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
