.DashboardHero {
    min-width: 300px;
    margin: 10px;
    max-width: calc(100% - 20px);

    .cardContent {
        display: flex;
    }
    .barChartContainer {
        margin-left: 1rem;
        border: 0.1rem solid rgba(112, 112, 112, 0.09);
        border-radius: 1rem;
        padding: 1rem;
        h3 {
            font-size: 1.8rem;
        }
    }
    .MuiTypography-h5 {
        font-size: 2.8rem;
        font-family: ttc-dbold;
    }

    @media screen and (max-width: 720px) {
        .cardContent {
            flex-direction: column;
        }
    }
}

.header {
    font-weight: 900;
}
.icons {
    width: '40px';
    height: '55px';
    padding: '5px';
}
.iconLabels {
    width: '40px';
    display: 'inline-block';
    text-align: 'center';
    padding: '5px';
}
.avatar {
    background-color: '#ff00';
}
