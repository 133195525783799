.full-width-clip-loader {
    width: calc(100vw - 70px);
    height: 80vh;
    align-items: center;
    display: flex;
}

.sublevel {
    width: 'auto';
    float: 'left';
    display: flex;
    flex-wrap: wrap;
}

.Dashboard {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.dashboardLoadingError {
    padding: 20px;
    margin-top: 10px;
}
