.top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

    .left {
        width: 45.7%;
        height: 7.4rem;
        border-radius: 0.8rem;
        -webkit-border-radius: 0.8rem;
        -moz-border-radius: 0.8rem;
        -ms-border-radius: 0.8rem;
        -o-border-radius: 0.8rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 0.5rem;
        margin-right: 1rem;
        border: 0.1rem solid rgba(112, 112, 112, 0.09);
        box-shadow: none;

        &:hover {
            cursor: pointer;
        }

        img {
            width: 4.25rem;
            height: auto;
        }

        h3 {
            font-family: ttc-dbold;
            font-size: 2rem;
            color: #000000;
        }
    }

    .right {
        width: 48.5%;
        height: 7.4rem;
        border-radius: 0.8rem;
        -webkit-border-radius: 0.8rem;
        -moz-border-radius: 0.8rem;
        -ms-border-radius: 0.8rem;
        -o-border-radius: 0.8rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 0.5rem;
        border: 0.1rem solid rgba(112, 112, 112, 0.09);
        background-color: #ffffff;
        box-shadow: none;

        &:hover {
            cursor: pointer;
        }

        img {
            width: 3.5rem;
            height: auto;
        }

        h3 {
            font-family: ttc-dbold;
            font-size: 2rem;
            color: #000000;
        }
    }
}

.bottom {
    font-size: 2rem;

    .box {
        height: 5rem;
        border-radius: 0.9rem;
        -webkit-border-radius: 0.9rem;
        -moz-border-radius: 0.9rem;
        -ms-border-radius: 0.9rem;
        -o-border-radius: 0.9rem;
        padding: 0 2.1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 0.1rem solid rgba(112, 112, 112, 0.15);
        margin-bottom: 1.1rem;

        span {
            font-size: 1.65rem;
            font-family: ttc-medium;
            color: #000000;
        }
    }
}
