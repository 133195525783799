.nav-element:hover {
    background-color: #2b4c8f;
}

.nav-element {
    margin: 15px 0;
    cursor: pointer;
    position: relative;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px 0;

    .icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        // outline-color: var(--neutral-grey-40);
        img {
            width: 24px;
            height: 24px;
        }
    }
    .links-container {
        background-color: #2b4c8f;
        color: #eee;
        cursor: default;
        width: 200px;
        display: inline-flex;
        flex-direction: column;
        position: absolute;
        left: 60px;
        top: -10px;
        padding: 0 0 15px 10px;
        z-index: 1000;
        a {
            color: #eee;
        }

        h1 {
            font-size: 16px;
            font-weight: 500;
            text-transform: uppercase;
        }
    }

    .links li {
        padding: 10px;
    }
}
