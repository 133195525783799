.content {
    height: 100vh;
    min-height: 100%;
    width: 100vw;
    display: flex;
    box-sizing: border-box;
}

.textInputGroup {
    padding: 10px;
    div {
        margin-right: 10px;
        margin-top: 5px;
    }
}
