.mepCostingDetails {
    div {
        margin: 5px;
    }
}

.bottomDetailButtons {
    padding-bottom: 10px;
}

.MuiInputBase-fullWidth {
    width: auto;
}
