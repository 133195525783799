.enhancedTableToolbar {
    display: flex;
    flex-direction: column;
    min-height: 40px;

    .titleRow {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0px 20px 0 20px;
    }
}
.enhancedTableFilter {
    h2 {
        margin: 0 0 5px 0;
    }
}

.MuiInputBase-input.MuiOutlinedInput-input {
    padding: 10px;
}
