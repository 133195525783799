.DashboardCard {
    min-width: 300px;
    margin: 10px;

    a {
        text-decoration: none;
        color: black;
    }

    .MuiCardHeader-title {
        font-family: ttc-dbold;
        font-size: 1.5rem;
    }
}
