.nav {
    //width: 9rem;
    //flex: 1 1;
    display: flex;
    height: 100%;
    flex-direction: column;
    border-right: 1px solid darkgrey;
    padding-top: 20px;
    background: linear-gradient(#0e175a, #2b4c8f);
    &.maintenanceMode {
        background: linear-gradient(#cc0000, #ff6900);
    }

    .MuiSvgIcon-colorPrimary {
        color: #ddd;
    }
    ul {
        display: flex;
        z-index: 1000;

        flex-direction: column;
        padding: 0;
        margin: 0;
        list-style: none;
    }

    #mainLogo {
        width: 50px;
        height: 50px;
        margin: 5px;
        margin-bottom: 50px;
    }
}
